// /!\ This file is whitelisted in package.json for having side-effects so that
// it's not tree-shaken away /!\

// This turns on passive events, see
// https://angular.io/guide/event-binding#binding-to-passive-events
(window as any).__zone_symbol__PASSIVE_EVENTS = [
  'touchstart',
  'touchmove',
  'wheel',
  'mousewheel',
  'scroll',
];
