/**
 * !!!!!!!!!!!!!!!!!!!! W A R N I N G !!!!!!!!!!!!!!!!!!!!
 * Webapp polyfills are shared with the SystemJS stack. Legacy pages must be tested when any change
 * to this file is made. See T254803 for an example of what could go wrong.
 */

// FIXME: T267853 - this hack is needed because some modules below (e.g. zone.js)
// have an AMD detection mechanism that breaks our shitty version of
// RequireJS on compat pages
/* eslint-disable-next-line */
const __HACK_CUR_DEFINE = window.define;
/* eslint-disable-next-line */
(window as any).define = undefined;

/* eslint-disable import/imports-first */
/* eslint-disable import/first */
/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/* eslint prefer-rest-params: 0 */
/* eslint no-prototype-builtins: 0 */
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */
/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/* eslint padding-line-between-statements: 0 */
import 'core-js/modules/es.string.trim-start';
import 'core-js/modules/es.string.trim-end';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */

import './zone-flags';
// eslint-disable-next-line local-rules/import-extensions
import 'zone.js'; // Included with Angular CLI.
import 'zone.js/plugins/task-tracking';

// Zone task tracking is turned off by default as it leaks memory; use the
// _enable_zone_task_tracking_ query param to turn it back on
if (
  !new URLSearchParams(document.location.search).has(
    '_enable_zone_task_tracking_',
  )
) {
  delete (window as any).Zone.TaskTrackingZoneSpec;
}

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
/* EdgeHTML polyfills/Older browser polyfills  */
// NOTE: TypeScript specific code to be able to import CommonJS packages.
// Ref: https://www.typescriptlang.org/docs/handbook/modules.html
import 'intersection-observer';
import 'requestidlecallback';
import * as smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

/* Global utils */
import 'hammerjs';
import 'first-input-delay';

// FIXME: T267853 - see https://github.com/angular/angular-cli/issues/9827#issuecomment-386154063
// sockjs-client currently needs that
(window as any).global = window;

// FIXME: T267853 - twilio-video is incompatible with Angular 12 https://github.com/twilio/twilio-video.js/issues/1517
(window as any).process = {
  env: { DEBUG: undefined },
  browser: true,
};

/* eslint-disable-next-line */
window.define = __HACK_CUR_DEFINE;
